import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from 'react-use-localstorage';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import langpacks from '../utils/intl';
import { DEFAULT_LOCALE, LOCALES } from '../constants/intl';
import { getLocale, setLocale } from '../reducers/locale';
import Loader from './Loader';

const Intl = ({ children }) => {
  const locale = useSelector(getLocale);
  const dispatch = useDispatch();
  const [langpack, setLangpack] = useState({});
  const [storedLocale, persistLocale] = useLocalStorage('langpack', DEFAULT_LOCALE);

  useEffect(() => {
    if (
      storedLocale !== DEFAULT_LOCALE
      && LOCALES.includes(storedLocale)
    ) {
      dispatch(setLocale(storedLocale));
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLangpack(await langpacks[locale]());
    })();
    persistLocale(locale);
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      messages={langpack}
    >
      {
        Object.keys(langpack).length === 0
          ? <Loader />
          : children
      }
    </IntlProvider>
  );
};

Intl.propTypes = {
  children: PropTypes.node,
};

Intl.defaultProps = {
  children: null,
};

export default Intl;
