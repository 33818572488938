import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import processSocketEvent from '../thunks/processSocketEvent';
import { setConnected } from '../reducers/isConnected';

const opts = {
  path: '/socket',
};

const SocketContext = createContext(null);

export const useSocket = () => {
  const { socket } = useContext(SocketContext);
  return socket;
};

export const useEmit = () => useSocket().emit;

export const useEmitEvent = (eventName) => {
  const socket = useSocket();
  return (data) => socket.emit(eventName, data);
};

const WithSocket = ({ children }) => {
  const [socket, setSocket] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, opts);
    newSocket.onAny((event, data) => {
      dispatch(processSocketEvent(newSocket, event, data));
    });

    newSocket.on('connect', () => {
      dispatch(setConnected(true));
    });

    newSocket.on('disconnect', () => {
      dispatch(setConnected(false));
    });

    setSocket(newSocket);

    return () => {
      setSocket(null);
      return newSocket.close();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

WithSocket.propTypes = {
  children: PropTypes.node,
};

WithSocket.defaultProps = {
  children: null,
};

export default WithSocket;
