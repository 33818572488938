import { combineReducers } from 'redux';
import { blackCard } from './blackCard';
import { committedCards } from './committedCards';
import { czar } from './czar';
import { hand } from './hand';
import { host } from './host';
import { isConnected } from './isConnected';
import { isInProgress } from './isInProgress';
import { isJudging } from './isJudging';
import { isWorking } from './isWorking';
import { locale } from './locale';
import { packs } from './packs';
import { player } from './player';
import { players } from './players';
import { token } from './token';
import { uncommittedCards } from './uncommittedCards';
import { winner } from './winner';

const reducer = combineReducers({
  blackCard,
  committedCards,
  czar,
  hand,
  host,
  isConnected,
  isInProgress,
  isJudging,
  isWorking,
  locale,
  packs,
  player,
  players,
  token,
  uncommittedCards,
  winner,
});

export const getState = (state) => state;

export default reducer;
