import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from 'axios';
import { FormattedMessage } from 'react-intl';

const HostName = ({ gameId }) => {
  const [host, setHost] = useState(undefined);

  useEffect(() => {
    (async () => {
      const {
        data: {
          host: hostId,
          players,
        },
      } = await http.get(`${process.env.REACT_APP_API_URL}/api/game/${gameId}`);
      setHost(players.find(({ id }) => id === hostId));
    })();
  }, [gameId]);

  return (
    host === undefined
      ? <FormattedMessage id="newGame.defaultHost" />
      : <span>{host.name}</span>
  );
};

HostName.propTypes = {
  gameId: PropTypes.string.isRequired,
};

export default HostName;
