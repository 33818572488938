const SET_CZAR = 'set_czar';
const CLEAR_CZAR = 'clear_czar';

const INITIAL_STATE = null;

export const setCzar = (czar) => ({
  type: SET_CZAR,
  czar,
});

export const clearCzar = () => ({
  type: CLEAR_CZAR,
});

export const czar = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_CZAR:
      return action.czar;
    case CLEAR_CZAR:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getCzar = (state) => state.czar;
