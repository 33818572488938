const SET_HOST = 'set_host';

const INITIAL_STATE = null;

export const setHost = (host) => ({
  type: SET_HOST,
  host,
});

export const host = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_HOST:
      return action.host;
    default:
      return state;
  }
};

export const getHost = (state) => state.host;
