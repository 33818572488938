const SET_PLAYERS = 'set_players';

const INITIAL_STATE = [];

export const setPlayers = (players) => ({
  type: SET_PLAYERS,
  players,
});

export const players = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_PLAYERS:
      return action.players;
    default:
      return state;
  }
};

export const getPlayers = (state) => state.players;

export const getPlayerById = (id) => (state) => state.players.find((player) => player.id === id);
