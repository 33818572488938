const SET_HAND = 'set_hand';
const ADD_TO_HAND = 'add_to_hand';
const REMOVE_FROM_HAND = 'remove_from_hand';
const CLEAR_HAND = 'clear_hand';

const INITIAL_STATE = [];

export const setHand = (hand) => ({
  type: SET_HAND,
  hand,
});

export const addToHand = (card) => ({
  type: ADD_TO_HAND,
  card,
});

export const removeFromHand = (card) => ({
  type: REMOVE_FROM_HAND,
  card,
});

export const clearHand = () => ({
  type: CLEAR_HAND,
});

export const hand = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_HAND:
      return action.hand;
    case ADD_TO_HAND:
      return [...state, action.card];
    case REMOVE_FROM_HAND:
      return state
        .filter(({ id, text }) => id !== action.card.id && text !== action.card.text);
    case CLEAR_HAND:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getHand = (state) => state.hand;
