const SET_JUDGING = 'set_is_judging';

const INITIAL_STATE = false;

export const setJudging = (isJudging) => ({
  type: SET_JUDGING,
  isJudging,
});

export const isJudging = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_JUDGING:
      return action.isJudging;
    default:
      return state;
  }
};

export const getIsJudging = (state) => state.isJudging;
