/* eslint-disable complexity */
import Event from '../constants/socketEvent';
import { setPlayer } from '../reducers/player';
import { setCzar } from '../reducers/czar';
import { setInProgress } from '../reducers/isInProgress';
import { setToken } from '../reducers/token';
import { setBlackCard } from '../reducers/blackCard';
import { setHand } from '../reducers/hand';
import { setCommittedCards } from '../reducers/committedCards';
import { setPacks } from '../reducers/packs';
import { setPlayers } from '../reducers/players';
import { setJudging } from '../reducers/isJudging';
import { clearWinner, setWinner } from '../reducers/winner';
import { SESSION_PLAYER_ID } from '../constants/sessionStorage';

const processSocketEvent = (socket, event, data) => async (dispatch) => {
  await (async () => {
    switch (event) {
      case Event.SET_TOKEN:
        return dispatch(setToken(data));

      case Event.SET_PLAYER:
        window.sessionStorage.setItem(SESSION_PLAYER_ID, data.id);
        return dispatch(setPlayer(data));

      case Event.START_ROUND:
        dispatch(clearWinner());
        return dispatch(setInProgress(true));

      case Event.SET_CZAR:
        return dispatch(setCzar(data));

      case Event.SET_BLACK_CARD:
        return dispatch(setBlackCard(data));

      case Event.SET_HAND:
        return dispatch(setHand(data));

      case Event.SET_SELECTED_CARDS:
        return dispatch(setCommittedCards(data));

      case Event.SET_PLAYERS:
        return dispatch(setPlayers(data));

      case Event.SET_JUDGING:
        return dispatch(setJudging(data));

      case Event.SET_WINNER:
        return dispatch(setWinner(data));

      case Event.SET_PACKS:
        return dispatch(setPacks(data));

      default:
        return () => {};
    }
  })();
};

export default processSocketEvent;
