import { BLACK, WHITE } from '../constants/card';

const SET_PACKS = 'set_packs';
const ADD_PACK = 'add_pack';
const REMOVE_PACK = 'remove_pack';
const CLEAR_PACKS = 'clear_packs';

const INITIAL_STATE = {
  [BLACK]: [],
  [WHITE]: [],
};

export const setPacks = (packs) => ({
  type: SET_PACKS,
  packs,
});

export const addPack = (pack, variant) => ({
  type: ADD_PACK,
  pack,
  variant,
});

export const removePack = (pack, variant) => ({
  type: REMOVE_PACK,
  pack,
  variant,
});

export const clearPacks = () => ({
  type: CLEAR_PACKS,
});

export const packs = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_PACKS:
      return action.packs;
    case ADD_PACK:
      return {
        ...state,
        [action.variant]: [
          ...state[action.variant],
          action.pack,
        ],
      };
    case REMOVE_PACK:
      return {
        ...state,
        [action.variant]: state[action.variant]
          .filter((pack) => pack !== action.pack),
      };
    case CLEAR_PACKS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getPacks = (state) => state.packs;
export const getIsPackInVariant = (pack, variant) => (state) => state.packs[variant].includes(pack);
