const SET_WORKING = 'set_working';
const CLEAR_WORKING = 'clear_working';

const INITIAL_STATE = false;

export const setWorking = (isWorking = true) => ({
  type: SET_WORKING,
  isWorking,
});

export const clearWorking = () => ({
  type: CLEAR_WORKING,
});

export const isWorking = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_WORKING:
      return action.isWorking;
    case CLEAR_WORKING:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getIsWorking = (state) => state.isWorking;
