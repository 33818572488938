const SET_PLAYER = 'set_player';

const INITIAL_STATE = {};

export const setPlayer = (player) => ({
  type: SET_PLAYER,
  player,
});

export const player = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_PLAYER:
      return action.player;
    default:
      return state;
  }
};

export const getPlayer = (state) => state.player;
export const getPlayerId = (state) => (
  state.player
    ? state.player.id
    : ''
);
