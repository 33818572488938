const SET_BLACK_CARD = 'set_black_card';

const INITIAL_STATE = null;

export const setBlackCard = (blackCard) => ({
  type: SET_BLACK_CARD,
  blackCard,
});

export const blackCard = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_BLACK_CARD:
      return action.blackCard;
    default:
      return state;
  }
};

export const getBlackCard = (state) => state.blackCard;
