/* eslint-disable react/jsx-filename-extension */
/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as Redux } from 'react-redux';
import App from './App';
import configureStore from './store';
import 'react-app-polyfill/stable';

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Redux store={store}>
      <App />
    </Redux>
  </React.StrictMode>,
  document.querySelector('#root')
);
