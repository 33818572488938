const SET_CONNECTED = 'set_connected';

const INITIAL_STATE = false;

export const setConnected = (isConnected) => ({
  type: SET_CONNECTED,
  isConnected,
});

export const isConnected = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_CONNECTED:
      return action.isConnected;
    default:
      return state;
  }
};

export const getIsConnected = (state) => state.isConnected;
