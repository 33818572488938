import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import CardsAgainstHumanity from './components/CardsAgainstHumanity';
import './App.scss';

const App = () => (
  <Router>
    <Helmet defaultTitle="The Lamb Sauce" />
    <CardsAgainstHumanity />
  </Router>
);

export default App;
