const SET_TOKEN = 'set_token';
const CLEAR_TOKEN = 'clear_token';

const INITIAL_STATE = null;

export const setToken = (token) => ({
  type: SET_TOKEN,
  token,
});

export const clearToken = () => ({
  type: CLEAR_TOKEN,
});

export const token = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_TOKEN:
      return action.token;
    case CLEAR_TOKEN:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getToken = (state) => state.token;
