const SET_COMMITTED_CARDS = 'set_committed_cards';
const CLEAR_COMMITTED_CARDS = 'clear_committed_cards';
const ADD_COMMITTED_CARDS = 'add_committed_cards';
const SET_PLAYER_CARDS_REVEALED = 'set_player_cards_revealed';

const INITIAL_STATE = [];

export const setCommittedCards = (selectedCards) => ({
  type: SET_COMMITTED_CARDS,
  selectedCards,
});

export const addCommittedCards = (cards) => ({
  type: ADD_COMMITTED_CARDS,
  cards,
});

export const clearCommittedCards = () => ({
  type: CLEAR_COMMITTED_CARDS,
});

export const setPlayerCardsRevealed = (playerId) => ({
  type: SET_PLAYER_CARDS_REVEALED,
  playerId,
});

export const committedCards = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_COMMITTED_CARDS:
      return action.selectedCards;
    case CLEAR_COMMITTED_CARDS:
      return INITIAL_STATE;
    case ADD_COMMITTED_CARDS:
      return [...state, action.cards];
    case SET_PLAYER_CARDS_REVEALED: {
      const cardSetIndex = state.findIndex((cardSet) => cardSet.player === action.playerId);
      const { player, cards } = state[cardSetIndex];

      return [
        ...state.slice(0, cardSetIndex),
        {
          player,
          cards,
          isRevealed: true,
        },
        ...state.slice(cardSetIndex + 1),
      ];
    }
    default:
      return state;
  }
};

export const getCommittedCards = (state) => state.committedCards;
