const SET_IN_PROGRESS = 'set_in_progress';

const INITIAL_STATE = false;

export const setInProgress = (isInProgress) => ({
  type: SET_IN_PROGRESS,
  isInProgress,
});

export const isInProgress = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_IN_PROGRESS:
      return action.isInProgress;
    default:
      return state;
  }
};

export const getIsInProgress = (state) => state.isInProgress;
