import { lazy, Suspense, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { HistoryListener, NavigationConfirmModal } from 'react-router-navigation-confirm';
import Loader from '../Loader';
import { setPlayer } from '../../reducers/player';
import { SESSION_GAME_ID } from '../../constants/sessionStorage';

const GameContainer = () => {
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const Game = lazy(() => import('./Game'));

  const playerId = useMemo(() => {
    const storedPlayerId = window.sessionStorage.getItem('playerId');

    if (storedPlayerId !== null) {
      dispatch(setPlayer({ id: storedPlayerId }));
    }

    return storedPlayerId;
  }, []);

  if (
    playerId === null
    || window.sessionStorage.getItem(SESSION_GAME_ID) !== gameId
  ) {
    return <Redirect to={`/join/${gameId}`} />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <HistoryListener>
        <NavigationConfirmModal />
        <Game />
      </HistoryListener>
    </Suspense>
  );
};

export default GameContainer;
