import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers/reducers';

const configureStore = (state) => {
  const compose = composeWithDevTools({ trace: true });
  return createStore(
    reducer,
    state,
    compose(applyMiddleware(thunkMiddleware))
  );
};

export default configureStore;
