import { Redirect, Route, Switch } from 'react-router-dom';
import WithSocket from './WithSocket';
import Intl from './Intl';
import NewGame from './NewGame/NewGame';
import GameContainer from './Game/GameContainer';

const CardsAgainstHumanity = () => (
  <WithSocket>
    <Intl>
      <div className="min-vh-100 py-3 d-flex flex-column">
        <Switch>
          <Route path="/join/:gameId">
            <NewGame />
          </Route>
          <Route path="/game/:gameId">
            <GameContainer />
          </Route>
          <Route path="/">
            <Redirect to="/join/new" />
          </Route>
        </Switch>
      </div>
    </Intl>
  </WithSocket>
);

export default CardsAgainstHumanity;
