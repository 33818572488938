import { DEFAULT_LOCALE } from '../constants/intl';

const SET_LOCALE = 'set_locale';

const INITIAL_STATE = DEFAULT_LOCALE;

export const setLocale = (locale) => ({
  type: SET_LOCALE,
  locale,
});

export const locale = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale;
    default:
      return state;
  }
};

export const getLocale = (state) => state.locale;
