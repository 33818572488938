import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useEmitEvent, useSocket } from '../WithSocket';
import Event from '../../constants/socketEvent';
import UsernameForm from './UsernameForm';
import HostName from './HostName';
import { SESSION_GAME_ID } from '../../constants/sessionStorage';

const NewGame = () => {
  const { gameId } = useParams();
  const history = useHistory();
  const join = useEmitEvent(Event.JOIN_GAME);
  const socket = useSocket();

  useEffect(() => {
    window.sessionStorage.clear();
    socket.once(Event.SET_GAME, (id) => {
      window.sessionStorage.setItem(SESSION_GAME_ID, id);
      history.push(`/game/${id}`);
    });
  }, []);

  const onSubmit = (playerName) => {
    join({ gameId, playerName });
  };

  return (
    <div className="container-fluid row">
      <div className="col col-md-6 offset-md-3 col-lg-4 offset-lg-4 d-flex flex-column vh-100 justify-content-center align-items-start">
        <UsernameForm onSubmit={onSubmit}>
          <p className="font-weight-bold mb-2">
            {
              gameId === 'new'
                ? <FormattedMessage id="newGame.create" />
                : (
                  <FormattedMessage
                    id="newGame.join"
                    values={{ host: <HostName gameId={gameId} /> }}
                  />
                )
            }
          </p>
        </UsernameForm>
        <div className="text-muted font-size-sm mt-4">
          <h6>
            <FormattedMessage id="privacy.title" />
          </h6>
          <p>
            <FormattedMessage id="privacy.body1" />
          </p>
          <p>
            <FormattedMessage id="privacy.body2" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewGame;
