const SET_WINNER = 'set_winner';
const CLEAR_WINNER = 'clear_winner';

const INITIAL_STATE = null;

export const setWinner = (player) => ({
  type: SET_WINNER,
  player,
});

export const clearWinner = () => ({
  type: CLEAR_WINNER,
});

export const winner = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_WINNER:
      return action.player;
    case CLEAR_WINNER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getWinner = (state) => state.winner;
