export default {
  // built-in
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',

  // client
  JOIN_GAME: 'join_game',
  START_ROUND: 'start_round',
  SELECT_CARDS: 'select_cards',
  REVEAL_CARDS: 'set_player_cards_revealed',
  CHOOSE_WINNER: 'choose_winner',
  SET_PACKS: 'set_packs',
  SET_CUSTOM_CARDS: 'set_custom_cards',
  SET_PARAMS: 'set_params',

  // server
  SET_TOKEN: 'set_token',
  SET_GAME: 'set_game',
  SET_PLAYER: 'set_player',
  SET_CZAR: 'set_czar',
  SET_BLACK_CARD: 'set_black',
  SET_HAND: 'set_hand',
  SET_SELECTED_CARDS: 'set_selected_cards',
  SET_PLAYERS: 'set_players',
  SET_JUDGING: 'set_judging',
  SET_WINNER: 'set_winner',
};
