const ADD_UNCOMMITTED_CARD = 'add_uncommitted_card';
const SET_UNCOMMITTED_CARDS = 'set_uncommitted_cards';
const REMOVE_UNCOMMITTED_CARD = 'remove_uncommitted_card';
const CLEAR_UNCOMMITTED_CARDS = 'clear_uncommitted_cards';

const INITIAL_STATE = [];

export const addUncommittedCard = (card) => ({
  type: ADD_UNCOMMITTED_CARD,
  card,
});

export const setUncommittedCards = (cards) => ({
  type: SET_UNCOMMITTED_CARDS,
  cards,
});

export const removeUncommittedCard = (card) => ({
  type: REMOVE_UNCOMMITTED_CARD,
  card,
});

export const clearUncommittedCards = () => ({
  type: CLEAR_UNCOMMITTED_CARDS,
});

export const uncommittedCards = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ADD_UNCOMMITTED_CARD:
      return [...state, action.card];
    case SET_UNCOMMITTED_CARDS:
      return action.cards;
    case REMOVE_UNCOMMITTED_CARD:
      return state.filter(({ id, text }) => id !== action.card.id && text !== action.card.text);
    case CLEAR_UNCOMMITTED_CARDS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getUncommittedCards = (state) => state.uncommittedCards;
