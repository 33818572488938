import PropTypes from 'prop-types';
import { useState } from 'react';
import { faArrowRight, faHandMiddleFinger } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const DEFAULT_SUBMIT_ICON = faArrowRight;

const UsernameForm = ({ onSubmit, children }) => {
  const [name, setName] = useState('');
  const [icon, setIcon] = useState(DEFAULT_SUBMIT_ICON);
  const onChangeName = ({ target: { value } }) => setName(value.slice(0, 10));

  const handleSubmit = () => {
    onSubmit(name);
  };

  const onMouseEnterButton = () => setIcon(faHandMiddleFinger);
  const onMouseLeaveButton = () => setIcon(DEFAULT_SUBMIT_ICON);

  return (
    <div className="w-100">
      {children}
      <Form.Group
        controlId="name"
        className="w-100"
      >
        <Form.Label>
          <FormattedMessage id="newGame.name" />
        </Form.Label>
        <div className="d-flex">
          <Form.Control
            value={name}
            onChange={onChangeName}
          />
          <Button
            className="ml-2"
            onClick={handleSubmit}
            onMouseEnter={onMouseEnterButton}
            onMouseLeave={onMouseLeaveButton}
          >
            <Icon
              icon={icon}
              fixedWidth={true}
            />
          </Button>
        </div>
      </Form.Group>
    </div>
  );
};

UsernameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
};

UsernameForm.defaultProps = {
  children: null,
};

export default UsernameForm;
